<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class=" d-sm-flex justify-content-between align-items-center pb-1  ">
          <h2 class="pt-2 pt-md-2 pt-xl-0">Expense Create</h2>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="controls">
                      <label>Expense Type*</label>
                      <VueMultiselect v-model="selectedExpenseType" class="multiselect-blue" :options="expenseTypes"
                                      :close-on-select="true" placeholder='Select category' label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="">
                      <label>Amount</label>
                      <input v-model="postExpenseData.amount" type="number" class="form-control"
                             placeholder="Enter amount $">
                    </div>
                    <div class="text-danger" v-if="errors.amount">{{ errors.amount }}</div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Date</label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="d-flex align-items-center">
                        <i class='bx bx-calendar'></i>
                      </div>
                      <div class="w-100 pl-1 ">
                        <div class="w-100 ">
                          <DatePicker v-model="postExpenseData.date" :update-on-input="true"
                                      :masks="{input: ['DD MMMM YYYY']}"
                                      :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.date">{{ errors.date }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 ">
                  <div class="form-group">
                    <div class="">
                      <label>Note</label>
                      <textarea v-model="postExpenseData.note" class="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 ">
                  <div class="d-flex justify-content-end">
                    <button @click="createExpense" type="button" class="btn btn-primary update-todo px-4">Create
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import {DatePicker} from "v-calendar";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  components: {
    DatePicker,
    AppLayout,

  },
  mixins: [ShowToastMessage],
  name: "ExpenseCreate",
  data() {
    return {
      selectedExpenseType: {
        value: '',
        name: 'None'
      },
      postExpenseData: {
        created_by: '',
        expense_type_id: '',
        amount: '',
        date: '',
        note: '',
      },
      errors: {
        amount: '',
        date: '',
      }

    }
  },
  watch: {
    selectedExpenseType() {
      this.postExpenseData.expense_type_id = this.selectedExpenseType.value;
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'appAuthLogin/authUser',
    }),
    expenseTypes() {
      let types = this.$store.getters['appExpenseTypes/expenseTypes'].map((type) => {
        let id = type.id;
        let name = type.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...types];
    },
  },
  methods: {
    ...mapActions({
      getExpenseTypes: 'appExpenseTypes/getExpenseTypes',
      postExpense: 'appExpenses/postExpense'
    }),
    async createExpense() {
      this.postExpenseData.created_by = this.authUser.id;
      this.postExpenseData = {};
      await this.postExpense(this.postExpenseData).then(async (response) => {
        if (response.status === 201) {
          const toastObj = {message: 'Expense created successful.', type: 'success'};
          this.showToastMessage(toastObj);

          await this.$router.replace({
            name: 'app.account.expense.list',
          });
        }
        this.errors.amount = response.errors && response.errors.amount ? response.errors.amount[0] : '';
        this.errors.date = response.errors && response.errors.date ? response.errors.date[0] : '';
      })
    }
  },
  mounted() {
    this.getExpenseTypes();
  }
}
</script>

<style>

</style>
