<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Expense Category </h2>
          <div>
            <router-link :to="{name:'app.expense.category.create'}">
              <button type="button" class="btn btn-primary glow  mb-1"><span class=""><i class='bx bx-plus '></i></span>
                <span class="align-middle ml-25">Add New</span></button>
            </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>

            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getExpenseCategoriesParams.name" class="form-control search-product-input-element"
                       type="text"
                       placeholder="Search a expense category by name">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyExpenseCategoryFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'95%'}">CATEGORY NAME
                      <div class="position-absolute sorting-up-button">
                        <i class="bx bx-chevron-up"></i>
                      </div>
                      <div class="position-absolute sorting-down-button">
                        <i class="bx bx-chevron-down"></i>
                      </div>
                    </th>
                    <th class="position-relative" :style="{width:'5%'}">
                      ACTIONS
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(expenseCategory) in expenseCategories" :key="expenseCategory.id">
                    <td>{{ expenseCategory.name }}</td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <div class="cursor-pointer" :style="{height:'18px'}"
                               @click="getSingleExpenseCategory(expenseCategory.id)" data-toggle="modal"
                               data-target="#expenseCategoryDetails">
                            <i class='bx bx-show'></i>
                          </div>
                        </div>
                        <router-link class="text-decoration-none"
                                     :to="{name:'app.expense.category.edit',params:{id:expenseCategory.id }}">
                          <div :style="{height:'18px'}">
                            <i class='bx bx-edit'></i>
                          </div>
                        </router-link>
                        <div class="text-decoration-none cursor-pointer">
                          <div data-toggle="modal" data-target="#deleteModal" :style="{height:'18px'}">
                            <i class='bx bx-x'></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="col-md-12 d-flex justify-content-end">
                  <ListPagination position="right" :pagination-data="paginateLinks"
                                  @getClickedPage="applyExpenseCategoryFilter"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ExpenseCategoryModal :expense-category="this.expenseCategory"/>
        <!-- <DeleteAlertModal/> -->
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import ExpenseCategoryModal
  from "@/views/backEnd/AccountAndFinance/Account/ExpenseCategory/includes/ExpenseCategoryModal";

import ListPagination from '@/components/backEnd/pagination/ListPagination';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// import DeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ExpenseCategoryList",
  components: {
    AppLayout,
    ListPagination,
    ExpenseCategoryModal,
    // DeleteAlertModal

  },
  mixins: [ShowToastMessage],
  data() {
    return {
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      getSettingsParams: {
        type: ['default'],
        key: ['pagination'],
      },
      expenseCategory: {},
      getExpenseCategoriesParams: {
        name: '',
        status: '',
        //order_by_name: 'asc',
        paginate: 1,
        pagination: '',
        page: ''
      }
    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getExpenseCategoriesParams.pagination = pagination.value;
    },
    selectedStatus: function (status) {
      this.getExpenseCategoriesParams.status = status.value;
    },
  },
  computed: {
    ...mapGetters({
      expenseCategories: 'appExpenseCategories/expenseCategories',
      paginateLinks: 'appExpenseCategories/paginateLinks',
    }),
    pagination() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
  },
  methods: {
    ...mapActions({
      getExpenseCategories: 'appExpenseCategories/getExpenseCategories',
      getExpenseCategory: 'appExpenseCategories/getExpenseCategory',
      getSettings: 'appSettings/getSettings',
    }),
    async getExpenseCategoriesList(expenseParam) {
      await this.getExpenseCategories(expenseParam).then((response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async getSingleExpenseCategory(id) {
      const params = {
        id: id,
        params: this.getExpenseCategoriesParams,
      };

      await this.getExpenseCategory(params)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.expenseCategory = this.$store.getters['appExpenseCategories/expenseCategory'];
            }

            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }

          });

    },
    async applyExpenseCategoryFilter(pageNumber) {
      this.getExpenseCategoriesParams.page = pageNumber;
      await this.getExpenseCategoriesList(this.getExpenseCategoriesParams);
    },
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
  },
  mounted() {
    this.getSettingList(this.getSettingsParams);
    this.getExpenseCategoriesList(this.getExpenseCategoriesParams);
  },

}
</script>

<style scoped>
.badge {
  display: inline-block;
  padding: 3px 3px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
</style>
