<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Create Expense Category </h2>
          <div>
            <router-link :to="{ name: previousRoute }">
              <button type="button" class="btn btn-danger glow mb-1">
                <span class><i class="bx bx-x"></i></span>
                <span class="align-middle ml-25">Cancel</span>
              </button>
            </router-link>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-lg-12">
            <div class="users-list-table">
              <div class="card">
                <div class="card-body">
                  <div class="row py-1">
                    <div class="col-12 ">
                      <div class="form-group">
                        <div class="">
                          <label>Category Name *</label>
                          <input v-model="postExpenseCategoryData.name" type="text" class="form-control"
                                 placeholder="Name">
                        </div>
                        <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start">
                    <button @click="postSingleExpenseCategory" type="button" class="btn btn-primary glow mb-0">
                      <span class="align-middle ml-25">Create</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions, mapGetters,
  
} from "vuex";

export default {
  name: "ExpenseCategoryCreate",
  components: {
    AppLayout,
  },
  mixins: [ShowToastMessage],
  computed: {
    ...mapGetters({
      previousRoute:'previousRoute'
    }),
  },
  data() {
    return {
      postExpenseCategoryData: {
        name: '',
      },
      errors: {
        name: ''
      }
    }
  },
  methods: {
    ...mapActions({
      postExpenseCategory: 'appExpenseCategories/postExpenseCategory'
    }),
    async postSingleExpenseCategory() {
      await this.postExpenseCategory(this.postExpenseCategoryData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          const toastObj = {message: 'Expense Category created successful.', type: 'success'};
          this.showToastMessage(toastObj);
          await this.$router.replace({
            name: 'app.expense.category.list',
          });
        }
        // GET ERRORS
        this.errors.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        if (response.message) {
          this.showToastMessage(response);
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
