<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Edit Expense Category </h2>
          <div>
            <router-link :to="{ name: previousNamedRoute }">
              <button type="button" class="btn btn-danger glow mb-1">
                <span class><i class="bx bx-x"></i></span>
                <span class="align-middle ml-25">Cancel</span>
              </button>
            </router-link>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-lg-12">
            <div class="users-list-table">
              <div class="card">
                <div class="card-body">
                  <div class="row py-1">
                    <div class="col-12 ">
                      <div class="form-group">
                        <div class="">
                          <label>Category Name *</label>
                          <input v-model="putExpenseCategoryData.name" type="text" class="form-control"
                                 placeholder="Name">
                        </div>
                        <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start">
                    <button @click="updateSingleExpenseCategory" type="button" class="btn btn-primary glow mb-0">
                      <span class="align-middle ml-25">Update</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "ExpenseCategoryEdit",
  components: {
    AppLayout,
  },
  mixins: [ShowToastMessage],

  data() {
    return {
      //expenseCategory: {},
      initialExpenseCategoryData: {
        name: ''
      },
      putExpenseCategoryData: {
        name: ''
      },
      getExpenseCategoriesParams: {
        name: '',
        status: '',
        order_by_id: 'desc',
        paginate: 1,
        pagination: '',
        page: ''
      },
      errors: {
        name: ''
      }
    }

  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      expenseCategory: 'appExpenseCategories/expenseCategory',
    }),
    isExpenseCategoryDataChanged() {
      return !(this.initialExpenseCategoryData.name === this.putExpenseCategoryData.name);
    }
  },
  methods: {
    ...mapActions({
      getExpenseCategory: 'appExpenseCategories/getExpenseCategory',
      putExpenseCategory: 'appExpenseCategories/putExpenseCategory'
    }),
    async getSingleExpenseCategory(id) {
      const paramObj = {
        id: id,
        params: this.getExpenseCategoriesParams,
      };
      await this.getExpenseCategory(paramObj).then((response) => {
        if (response.status === 200) {
          //assigning Ids
          this.expenseCategoryId = this.expenseCategory.id;

          //expense category model
          this.initialExpenseCategoryData = {
            name: this.expenseCategory && this.expenseCategory.name ? this.expenseCategory.name : '',

          };
          this.putExpenseCategoryData = {
            name: this.expenseCategory && this.expenseCategory.name ? this.expenseCategory.name : '',
          };
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async updateSingleExpenseCategory() {
      if (this.isExpenseCategoryDataChanged) {
        const expenseCategoryPramObj = {
          id: this.expenseCategoryId,
          data: this.putExpenseCategoryData,
        };
        await this.putExpenseCategory(expenseCategoryPramObj).then(async (response) => {
          if (response.status === 200) {
            let toastObj = {message: 'Expense Category related info has Updated', type: 'success'};
            this.showToastMessage(toastObj);
            await this.$router.replace({
              name: 'app.expense.category.list',
            });
          }
          // GET ERRORS
          this.errors.name = response.errors && response.errors.name ? response.errors.name[0] : '';
          if (response.message) {
            this.showToastMessage(response);
          }
        });
      }
    },
  },
  mounted() {
    const routeParams = this.$route.params;
    this.getSingleExpenseCategory(routeParams.id);
  },
}
</script>

<style scoped>

</style>
