<template>
  <div class="modal fade " id="expenseCategoryDetails" tabindex="-1" role="dialog"
       aria-labelledby="warehouseDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Expense Category Details</h5>
          <button @click="resetExpenseCategory" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th class="col-3">Name</th>
              <th class="col-9">{{ name }}</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer border-0 py-1">
          <button @click="resetExpenseCategory" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExpenseCategoryModal',
  props: ['expenseCategory'],
  data() {
    return {
      id: '',
      name: '',
    }
  },
  watch: {
    expenseCategory() {
      this.id = this.expenseCategory && this.expenseCategory.id ? this.expenseCategory.id : '';
      this.name = this.expenseCategory && this.expenseCategory.name ? this.expenseCategory.name : '';

    },
  },
  methods: {
    resetExpenseCategory() {
      Object.assign(this.$data, this.$options.data());
    }
  },
}
</script>

<style scoped>

</style>